.pagination{
    display:flex;
    justify-content:flex-end;
    gap:10px
}

button{
    padding: 10px;
    background: #fff;
    border: 1px solid #146595;
    border-radius: 5px;
    box-shadow: 0 5px 20px #3423;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

button:disabled{
    border: 1px solid rgba(174, 204, 223, 0.62);
    cursor: not-allowed;
}


.pagination button:hover,.pagination .active{
    background: #146595!important;
    color: #fff;
}
