.search-input{
    background: #fff;
    box-shadow: 0 5px 20px #8e8eb147;
    border-radius: 10px;
    opacity: 1;
    border: 2px solid #ededed;
}

.search-input {
    padding-left: 5px;
    width: -webkit-fill-available;
    min-height: 35px;
}
