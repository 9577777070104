table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
}

table, tr, td, th{
   text-align: justify;
}

td,th{
    padding: 12px;
}


tr, .card, input[type="text"],input[type="email"]{
    background: #fff;
    box-shadow: 0 5px 20px #8e8eb147;
    border-radius: 10px;
    opacity: 1;
    border: 2px solid #ededed;
}

input[type="text"],input[type="email"]{
    padding: 10px!important;
}

.card{
    margin-top: 20px;
    padding: 20px;
    font-weight:bold;
    font-size: 15px;
}


.container{
    padding: 20px;
}

.icon{
    max-width: 25px!important;
}

a{
    cursor: pointer;
}

input[type="checkbox"]{
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.delete-btn{
    background: rgba(255, 52, 52, 0.89);
    color: #fff;
    border: none;
}
